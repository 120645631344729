@font-face {
    font-family: "Poppins";
    font-weight: 200;
    src: local("Poppins"),
        url(./fonts/Poppins-ExtraLight.ttf) format("truetype");
}

@media screen and (max-width: 600px) {
    html {
        font-size: 12px;
    }
    .forside-tittel {
        font-size: 8px;
    }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
    html {
        font-size: 12px;
    }
}

body {
    margin: 0;
    font-family: "Poppins", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
        "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fafafa;
}

div .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.75);
}

.MuiFab-label .MuiSvgIcon-root {
    font-size: 24px;
}

a {
    text-decoration: none;
    color: #000000;
}

a:hover {
    text-decoration: underline;
}

.button-light {
    color: #e6e6e6;
}

.button-light:active {
    color: #c8c8c8;
}


.button-dark {
    color: #000000;
}

.button-dark:hover {
    color: #1e1e1e;
}
